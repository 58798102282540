import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 327.000000 357.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,357.000000) scale(0.100000,-0.100000)">
          
          <path d="M0 1785 l0 -1785 1635 0 1635 0 0 1785 0 1785 -1635 0 -1635 0 0
-1785z m1837 1404 c235 -31 502 -144 678 -286 82 -66 205 -199 272 -293 389
-543 328 -1293 -143 -1775 -193 -197 -436 -330 -714 -390 -107 -23 -316 -30
-452 -15 -129 14 -327 74 -456 139 -263 133 -513 393 -636 664 -210 457 -144
1037 162 1420 103 130 272 283 382 346 161 93 346 161 495 185 110 17 307 20
412 5z"/>
<path d="M1500 3169 c-328 -38 -601 -173 -837 -417 -433 -446 -501 -1165 -159
-1684 106 -162 290 -343 426 -420 264 -151 449 -202 725 -201 285 1 513 68
747 219 261 168 464 438 557 739 99 321 74 684 -66 983 -34 73 -44 91 -122
212 -44 68 -183 215 -273 286 -273 219 -642 323 -998 283z m150 -159 c0 -16
-7 -20 -32 -20 -18 0 -39 -4 -47 -9 -22 -14 -5 -41 26 -41 65 0 97 -66 53
-110 -28 -28 -110 -29 -110 -1 0 16 8 20 43 23 34 2 42 7 42 23 0 16 -8 21
-35 23 -60 6 -88 47 -64 94 15 29 29 35 87 37 31 1 37 -2 37 -19z m264 -5 c34
-5 46 -12 46 -24 0 -27 -8 -30 -47 -19 -27 7 -38 7 -44 -4 -15 -24 -10 -35 20
-41 23 -5 28 -10 24 -26 -4 -16 -11 -19 -34 -14 -17 3 -29 1 -29 -5 0 -6 -3
-17 -6 -25 -4 -11 6 -16 40 -21 29 -5 46 -12 46 -21 0 -23 -18 -27 -75 -15
-63 13 -61 9 -39 118 21 105 24 114 39 109 7 -3 33 -9 59 -12z m-563 -42 c6
-16 19 -61 30 -102 15 -59 16 -76 7 -83 -7 -4 -16 -8 -19 -8 -9 0 -61 199 -54
210 11 18 25 11 36 -17z m-157 -140 c49 -78 53 -90 40 -103 -13 -13 -16 -13
-23 0 -5 8 -20 32 -32 53 l-24 39 -3 -68 c-3 -63 -13 -94 -32 -94 -5 0 -22 23
-39 50 -17 28 -33 50 -35 50 -2 0 -1 -32 2 -71 5 -68 -2 -88 -29 -89 -4 0 -10
44 -14 98 -4 53 -9 107 -11 119 -3 15 3 26 16 33 21 11 40 6 40 -10 0 -13 50
-85 51 -74 0 5 0 38 -1 73 -1 59 7 80 32 81 3 0 31 -39 62 -87z m1222 -75 c16
-30 5 -68 -34 -117 -32 -40 -38 -43 -72 -38 -34 4 -100 42 -100 57 0 3 30 43
66 88 l66 82 31 -26 c18 -15 37 -35 43 -46z m-570 -113 c77 -19 81 -20 177
-65 285 -134 477 -439 477 -758 0 -290 -137 -544 -382 -708 -166 -111 -390
-164 -575 -135 -78 13 -174 37 -203 52 -8 4 -35 17 -60 29 -229 112 -415 355
-461 602 -16 91 -14 251 6 335 24 104 47 163 101 255 127 216 352 368 607 409
73 11 231 3 313 -16z m-1018 -10 c46 -19 85 -68 85 -107 0 -39 -39 -78 -77
-78 -41 0 -126 75 -126 111 0 39 33 76 77 88 2 1 20 -6 41 -14z m1822 -110
c16 -19 10 -26 -80 -95 -68 -52 -80 -58 -91 -44 -10 13 -10 17 0 24 19 11 -18
61 -40 54 -16 -4 -47 22 -36 32 5 5 187 41 218 43 9 1 22 -6 29 -14z m-1981
-161 c12 -15 21 -33 21 -40 0 -10 13 -14 41 -14 33 0 40 -3 37 -17 -2 -13 -16
-19 -47 -23 -25 -3 -46 -11 -49 -17 -2 -7 3 -13 10 -13 26 0 51 -26 42 -42 -8
-13 -21 -10 -94 26 -119 57 -113 50 -88 101 33 68 91 85 127 39z m2110 -70
c20 -47 22 -57 10 -65 -21 -13 -26 -11 -32 12 -4 13 -11 19 -19 16 -128 -56
-168 -65 -168 -38 0 5 36 24 81 42 70 29 79 36 75 55 -3 11 -3 24 1 28 16 15
30 2 52 -50z m-2207 -221 c12 -11 18 -30 18 -58 0 -41 2 -43 35 -49 26 -5 35
-12 35 -26 0 -11 -3 -20 -6 -20 -25 0 -201 34 -207 40 -12 10 8 88 29 111 21
23 71 25 96 2z m2186 -36 c89 -29 127 -57 105 -77 -4 -4 -39 -12 -78 -18 -38
-6 -84 -14 -101 -18 -26 -5 -32 -2 -37 15 -3 14 0 21 9 21 24 0 16 51 -11 76
-14 13 -25 28 -25 34 0 13 0 12 138 -33z m-2095 -363 c15 -72 -10 -113 -69
-114 -35 0 -59 22 -69 63 -6 24 -12 27 -47 27 -32 0 -39 3 -36 17 2 13 21 20
77 30 40 6 78 14 85 16 31 13 51 -1 59 -39z m2088 30 c46 -9 89 -16 94 -17 15
-3 24 -19 18 -33 -2 -7 -10 -11 -17 -8 -20 8 -30 -55 -12 -75 7 -9 11 -24 7
-33 -5 -15 -22 -5 -101 58 -52 42 -94 82 -94 90 4 41 -6 39 105 18z m-121
-249 c-8 -21 -3 -25 68 -52 58 -23 77 -35 77 -49 0 -25 -29 -22 -97 11 -60 29
-74 31 -82 10 -6 -17 -32 -20 -41 -6 -3 5 6 34 20 64 20 45 29 54 44 50 16 -4
18 -9 11 -28z m-1875 -114 c-7 -24 -36 -55 -54 -59 -8 -2 -27 1 -42 7 -26 9
-31 7 -50 -21 -21 -28 -23 -29 -35 -13 -11 15 -9 23 12 54 19 28 21 37 10 39
-8 2 -25 -2 -37 -8 -17 -10 -23 -9 -30 1 -5 8 -9 16 -9 19 0 3 44 26 98 51
l97 46 23 -48 c13 -27 21 -57 17 -68z m1844 -151 c60 -45 75 -75 36 -75 -32 0
-45 -14 -45 -49 0 -41 -26 -69 -36 -40 -4 11 -22 52 -40 91 -43 93 -49 120
-34 138 11 13 15 13 35 -3 13 -9 50 -37 84 -62z m-1717 -14 c44 -33 42 -91 -3
-132 -67 -60 -114 -63 -152 -12 -27 36 -16 72 36 120 45 40 86 49 119 24z
m1498 -175 c60 -63 59 -108 -4 -163 -32 -28 -46 -34 -46 -20 0 5 -8 17 -18 28
-47 51 -104 136 -96 143 75 64 112 67 164 12z m-1242 -34 c8 -9 5 -19 -8 -37
-11 -14 -20 -30 -20 -37 0 -6 -6 -13 -12 -16 -7 -2 -10 -8 -6 -11 3 -4 33 7
66 25 50 28 62 31 75 21 14 -12 10 -22 -30 -90 -57 -95 -69 -110 -83 -96 -12
12 4 57 27 82 7 7 13 18 13 23 0 12 -17 11 -25 -2 -4 -5 -17 -14 -28 -20 -12
-5 -31 -16 -42 -24 -19 -13 -23 -13 -43 0 l-21 16 36 56 36 57 -39 -24 c-21
-13 -51 -30 -65 -38 -26 -16 -59 -11 -59 8 0 5 36 31 80 56 45 26 83 51 86 56
8 12 50 10 62 -5z m257 -117 c4 -6 -2 -41 -13 -78 -11 -37 -23 -82 -27 -99 -5
-24 -12 -33 -26 -33 -24 0 -24 3 6 116 14 52 25 96 25 99 0 9 29 5 35 -5z
m533 -22 c2 -12 -7 -18 -37 -23 -60 -10 -66 -57 -6 -52 42 3 48 -31 7 -39 -24
-5 -28 -10 -24 -31 5 -25 11 -26 75 -7 11 4 17 0 17 -10 0 -9 1 -19 3 -23 1
-3 -5 -7 -13 -9 -8 -1 -35 -7 -60 -13 -24 -5 -47 -8 -50 -5 -5 6 -38 169 -39
196 -2 32 121 49 127 16z m-268 -28 c0 -17 -7 -20 -44 -20 -25 0 -48 -5 -51
-10 -10 -16 14 -40 39 -40 53 -1 79 -61 45 -104 -18 -23 -27 -27 -68 -24 -38
3 -47 7 -49 25 -3 19 0 21 36 16 34 -5 42 -2 51 16 7 11 10 21 9 22 -2 0 -16
3 -33 5 -38 7 -75 41 -75 71 0 39 34 63 90 63 43 0 50 -3 50 -20z"/>
<path d="M2306 2713 c-20 -25 -36 -52 -36 -59 0 -13 22 -24 46 -24 17 0 60 46
70 73 5 17 -20 57 -36 57 -4 0 -24 -21 -44 -47z"/>
<path d="M1508 2610 c-72 -12 -145 -38 -228 -81 -201 -104 -350 -287 -412
-506 -30 -106 -32 -305 -4 -408 64 -237 213 -422 421 -525 127 -63 193 -80
331 -87 190 -10 379 47 525 157 109 82 226 234 259 336 7 21 16 44 20 49 4 6
16 45 25 88 27 121 18 316 -19 422 -32 91 -76 185 -88 185 -4 0 -8 5 -8 11 0
21 -104 141 -165 191 -98 80 -237 142 -374 168 -62 12 -214 11 -283 0z m304
-65 c140 -29 295 -119 396 -230 185 -202 244 -489 153 -751 -40 -117 -83 -186
-176 -279 -152 -154 -321 -225 -535 -225 -100 0 -188 14 -245 40 -22 10 -57
25 -79 34 -52 22 -153 94 -210 153 -84 85 -138 174 -182 303 -26 77 -28 97
-28 220 0 145 13 207 68 322 82 172 245 322 416 384 125 45 291 56 422 29z"/>
<path d="M1333 2425 c-75 -32 -106 -138 -60 -205 94 -139 301 -44 258 118 -22
82 -116 123 -198 87z m102 -41 c46 -21 68 -69 54 -117 -25 -90 -145 -98 -186
-13 -20 43 -12 75 28 115 34 35 56 38 104 15z"/>
<path d="M1213 2140 c-60 -36 -67 -72 -47 -229 19 -150 19 -151 50 -184 24
-27 27 -41 50 -245 27 -250 30 -261 52 -279 26 -19 125 -17 151 5 24 19 28 40
56 312 33 324 49 455 56 464 4 5 70 13 146 18 163 11 186 20 181 76 -2 28 -9
40 -28 48 -14 7 -113 17 -220 24 l-195 11 -35 -40 -36 -41 -35 40 c-31 34 -42
40 -75 40 -21 0 -53 -9 -71 -20z m128 -65 c19 -24 41 -45 50 -45 10 0 34 20
55 46 l39 45 182 -12 c100 -7 187 -16 194 -20 7 -4 9 -14 6 -22 -4 -11 -31
-16 -114 -21 -224 -13 -223 -13 -223 -77 0 -19 -9 -107 -19 -195 -11 -87 -27
-240 -37 -339 -9 -99 -21 -186 -27 -192 -12 -17 -79 -17 -99 0 -12 9 -22 66
-41 246 -25 225 -27 235 -53 262 -25 26 -30 44 -45 156 -10 71 -15 139 -12
151 6 27 57 62 89 62 14 0 34 -16 55 -45z"/>
<path d="M1971 1966 c-7 -8 -26 -81 -44 -162 l-32 -148 -13 59 c-15 71 -26 85
-61 85 -39 0 -47 -9 -80 -83 -29 -64 -32 -67 -65 -67 -58 0 -86 -16 -86 -47 0
-50 10 -54 113 -56 l95 -2 12 -60 c27 -136 38 -155 86 -155 43 0 45 4 88 195
l33 150 17 -57 c21 -73 38 -82 129 -75 76 7 97 18 97 52 0 31 -19 44 -72 51
l-44 6 -18 72 c-41 162 -65 239 -78 247 -21 13 -64 10 -77 -5z m72 -92 c10
-32 19 -66 21 -74 31 -127 45 -183 48 -185 2 -2 22 -5 46 -8 77 -7 57 -24 -27
-23 -48 1 -45 -3 -83 141 -6 22 -12 46 -14 53 -8 27 -32 10 -43 -30 -11 -41
-81 -364 -81 -374 0 -2 -6 -4 -14 -4 -17 0 -20 8 -41 115 -26 127 -35 155 -50
155 -8 0 -21 -13 -30 -30 -13 -26 -21 -30 -53 -29 -39 1 -102 15 -89 19 18 6
30 8 67 9 40 1 40 2 75 76 48 104 51 103 79 -36 9 -42 18 -84 22 -93 8 -21 31
-21 38 2 8 25 35 146 52 232 24 120 34 152 47 147 6 -2 19 -30 30 -63z"/>
<path d="M766 2584 c-22 -21 -20 -28 15 -69 34 -38 72 -46 87 -17 14 25 -2 57
-41 81 -41 25 -41 25 -61 5z"/>
<path d="M2533 2473 c-13 -2 -23 -11 -23 -19 0 -20 24 -17 45 6 10 11 14 19 9
19 -5 -1 -19 -4 -31 -6z"/>
<path d="M592 2328 c-20 -20 -14 -48 13 -60 31 -14 36 -9 38 34 2 26 -2 34
-18 36 -11 1 -26 -3 -33 -10z"/>
<path d="M497 2033 c-9 -9 -9 -53 1 -53 4 0 17 -3 30 -6 20 -6 22 -3 22 30 0
31 -3 36 -23 36 -13 0 -27 -3 -30 -7z"/>
<path d="M2710 1978 c0 -14 5 -18 16 -14 9 3 22 6 30 6 23 0 7 17 -21 22 -20
4 -25 1 -25 -14z"/>
<path d="M563 1644 c-9 -24 10 -49 38 -49 20 0 24 5 24 30 0 25 -4 30 -28 33
-18 2 -29 -2 -34 -14z"/>
<path d="M2745 1636 c24 -19 48 -21 43 -3 -3 6 -18 13 -34 15 -28 3 -28 3 -9
-12z"/>
<path d="M668 1379 c-34 -19 -11 -77 25 -63 22 8 29 32 17 55 -12 22 -18 23
-42 8z"/>
<path d="M2540 1166 c0 -19 22 -56 30 -51 16 10 12 29 -10 43 -11 7 -20 10
-20 8z"/>
<path d="M785 1108 c-27 -25 -34 -38 -29 -55 10 -35 39 -38 78 -8 41 31 46 42
35 73 -12 31 -43 28 -84 -10z"/>
<path d="M2273 976 c-9 -11 -7 -20 12 -42 13 -16 32 -40 44 -54 l20 -25 21 21
c19 19 20 25 10 45 -32 62 -79 86 -107 55z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
